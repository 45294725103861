export const ProductTypes = [
    {
        'product_code': '382d380722c4434c87655465f64a181f',
        'feature': [
            'Health Department Inspection',
            'Food Temperature Recording',
            'Food Cooling Log'
        ],
        'texts': [
            'Hardware Sold Separately',
            'TempTest™ Blue',
            'Thermapen® Blue'
        ]
    },
    {
        'product_code': 'a46a5f70a3844bf3b1bea9ff3f108efc',
        'feature': [
            'Health Department Inspection',
            'Food Temperature Recording',
            'Food Cooling Log',
            'Score Card',
            '6 Months Data Storage'
        ],
        'texts': [
            'Hardware Sold Separately',
            'TempTest™ Blue',
            'Thermapen® Blue',
            'DishTemp® Blue'
        ]
    },
    {
        'product_code': 'a5e0d03876014592a5d543bdf1883350',
        'feature': [
            'Health Department Inspection',
            'Food Temperature Recording',
            'Food Cooling Log',
            'Score Card',
            '6 Months Data Storage'
        ],
        'texts': [
            'Hardware Sold Separately',
            'TempTest™ Blue',
            'Thermapen® Blue',
            'DishTemp® Blue',
            'ThermaData® WiFi Restaurant Walk-in Kit'
        ]
    },
    {
        'product_code': '1f61c89a9eaa44a7960deb61d5b44a76',
        'feature': [
            'Health Department Inspection',
            'Food Temperature Recording',
            'Food Cooling Log',
            'Score Card',
            '6 Months Data Storage'
        ],
        'texts': [
            'Hardware Sold Separately',
            'TempTest™ Blue',
            'Thermapen® Blue',
            'DishTemp® Blue',
            'ThermaData® WiFi Restaurant Walk-in Kit'
        ]
    }
]

export const ProductTypesDeviceLinkMap = {
    "TempTest™ Blue": "https://www.avantlink.com/click.php?tool_type=cl&merchant_id=504989e8-60a3-41a9-bf0f-e83e99fc256e&website_id=d7b9de21-a0d9-4760-8146-496cab855701&url=https%3A%2F%2Fwww.thermoworks.com%2Ftemptest-blue%2F",
    "Thermapen® Blue": "https://www.avantlink.com/click.php?tool_type=cl&merchant_id=504989e8-60a3-41a9-bf0f-e83e99fc256e&website_id=d7b9de21-a0d9-4760-8146-496cab855701&url=https%3A%2F%2Fwww.thermoworks.com%2Fthermapen-blue%2F",
    "DishTemp® Blue": "https://www.avantlink.com/click.php?tool_type=cl&merchant_id=504989e8-60a3-41a9-bf0f-e83e99fc256e&website_id=d7b9de21-a0d9-4760-8146-496cab855701&url=https%3A%2F%2Fwww.thermoworks.com%2Fdishtemp-blue%2F",
    "ThermaData® WiFi Restaurant Walk-in Kit": "https://www.avantlink.com/click.php?tool_type=cl&merchant_id=504989e8-60a3-41a9-bf0f-e83e99fc256e&website_id=d7b9de21-a0d9-4760-8146-496cab855701&url=https%3A%2F%2Fwww.thermoworks.com%2Fthermadata-wifi-restaurant-kit-2%2F"
}

export const wordpressUrl = process.env.WORDPRESS_API_URL;

export const defaultLang = 'en'