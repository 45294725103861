import { gql } from "@apollo/client";

const ADD_TO_CART = gql`
mutation ($input: AddToCartInput!) {
  addToCart(input:$input) {
    clientMutationId
    cartItem{
        key
    }
    cart {
      contents {
        itemCount
        nodes {
          quantity
          product {
            node {
              name
              shortDescription
            }
          }
        }
      }
    }
  }
}
`;
export default ADD_TO_CART;
